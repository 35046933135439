import React, { useEffect, useReducer } from "react"
import { AppReducer } from "./AppReducer"
import Modal from "../components/Elements/Modal"
import Toast from "../components/Layout/Toast"
import Disclaimer from "../components/Layout/Disclaimer"

export const initialState = {
  orderForm: {
    fullName: "",
    email: "",
    emailBody: "",
    prescriptionFiles: [],
  },
  documents: [],

  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  disclaimer: {
    isActive: false,
    header: null,
    message: null,
    color: null,
  },
  websiteName: "",
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  useEffect(() => {
    let finalWebsiteName = "MG Health Solutions"
    if (window?.location?.hostname?.toLowerCase()?.includes("wellbridge"))
      finalWebsiteName = "Wellbridge Health"
    dispatch({ type: "SET_WEBSITE_NAME", payload: finalWebsiteName })
  }, [])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state?.modal?.isActive}
        isFullheight={state?.modal?.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state?.modal?.heading}
        modalHeaderClass={state?.modal?.headerClass}
        modalBackground={state?.modal?.background}
        hideCloseButton={state?.modal?.hideCloseButton}
        isCard={state?.modal?.isCard}
      >
        {state?.modal?.content}
      </Modal>
      <Toast
        isActive={state?.toast?.isActive}
        message={state?.toast?.message}
        color={state?.toast?.color}
      />
      {children}
      <Disclaimer
        isActive={state?.disclaimer?.isActive}
        message={state?.disclaimer?.message}
        header={state?.disclaimer?.header}
        color={state?.disclaimer?.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      />
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
